import actions from '../constants/reduxActions';
import CountryModel from '../models/country.model';

const initialState = {
	items: [],
};

export default function(state = initialState, action) {
	switch (action.type) {
		case actions.countries.list.set:
			return {
				...state,
				items: action.payload.items.map(country => new CountryModel(country)),
			};
		default:
			return state;
	}
}
