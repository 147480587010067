import actions from '../constants/reduxActions';
import RedirectSubDomainModel from '../models/redirectSubDomain.model';

const initialState = {
	items: {},
};

export default function(state = initialState, action) {
	switch (action.type) {
		case actions.redirectSubDomains.item.set:
			const redirectSubDomainModel = new RedirectSubDomainModel(
				action.payload.data,
			);
			return {
				...state,
				items: {
					...state.items,
					[redirectSubDomainModel.id]: redirectSubDomainModel,
				},
			};
		default:
			return state;
	}
}
