export const ACCOUNTANT = 'accountant';
export const ADMIN = 'admin';
export const ADMIN_CUT = 'admin_cut';
export const WORKER = 'worker';
export const AFFILIATE = 'affiliate';
export const TEAM_LEAD = 'team_lead';
export const MARKUPER = 'markup';
export const MANAGER = 'manager';
export const ANALYTIC = 'analytic';
export const SUPPORT = 'support';
export const TECH_ADMIN = 'tech_admin';
