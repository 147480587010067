import actions from '../constants/reduxActions';
import UserModel from '../models/user.model';
import UserService from '../services/user.service';

const initialState = {
	items: {},
};

export default function(state = initialState, action) {
	switch (action.type) {
		case actions.users.item.set:
			const userModel = new UserModel(action.payload.data);
			return { ...state, items: { ...state.items, [userModel.id]: userModel } };
		case actions.users.leadColumns.get:
			const sessionColumns = JSON.parse(localStorage.getItem('leadColumns'));
			const leadColumns = UserService.setLeadColumns(action.payload);
			return {
				...state,
				leadColumns: Object.keys(leadColumns).reduce((obj, column) => {
					if (column in sessionColumns) {
						obj[column] = sessionColumns[column];
					} else {
						obj[column] = leadColumns[column];
					}
					return obj;
				}, {}),
			};
		default:
			return state;
	}
}
