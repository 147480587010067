import BaseModel from './base.model';

class CountryModel extends BaseModel {
	constructor(data) {
		super();
		data = data || {};

		this.id = data.id || 0;
		this.name = data.label || '';
		this.label = data.label || '';
		this.value = data.value || '';
		this.region = data.region || '';
	}
}

export default CountryModel;
