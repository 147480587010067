import actions from '../constants/reduxActions';
import PreLandingModel from '../models/preLanding.model';

const initialState = {
	preLandings: {},
};

export default function(state = initialState, action) {
	switch (action.type) {
		case actions.preLandings.item.set:
			const preLandingModel = new PreLandingModel(action.payload.data);
			return {
				...state,
				preLandings: {
					...state.preLandings,
					[preLandingModel.id]: preLandingModel,
				},
			};
		default:
			return state;
	}
}
