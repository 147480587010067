import Base from './base.model';
import { formatDateTime } from '../helpers/functions';

class Landing extends Base {
	constructor(data) {
		super();
		data = data || {};

		this.id = data.id || '';
		this.alt = data.alt || '';
		this.geo = data.geolocation || '';
		this.name = data.name || '';
		this.path = data.path || '/';
		this.frontEndDev = data.frontEndDev || '';
		this.frontKreo = data.frontKreo || '';
		this.backKreo = data.backKreo || '';
		this.status = data.status ?? 1;
		this.category = data.category || '';
		this.client = data.client || '';
		this.createdAt =
			Boolean(data.created_at) === true
				? formatDateTime(data.created_at)
				: undefined;
	}
}

export default Landing;
