import Base from './base.model';

class SubDomain extends Base {
	constructor(data) {
		super();
		data = data || {};

		this.id = data.id || '';
		this.name = data.name || '';
		this.kreo = data.kreo || '';
		this.kreoMain = data.kreoMain || '';
		this.accountType = data.accountType || '';
		this.application = data.application || '';
		this.user = data.user || '';
		this.cloaca = data.cloaca || false;
		this.fbtool = data.fbtool || false;
		this.domain = data.domain || '';
		this.status = Number(data.status) || 0;
		this.fbpixels = data.fbpixels || [];
		this.createdAt = this.formatDate(data.createdAt);
		this.preLanding = data.preLanding || '';
		this.cardSystem = data.cardSystem || '';
		this.postLanding = data.postLanding || '';
		this.accountName = data.accountName || '';
		this.demonetization = data.demonetization || '';
		this.antidectProfile = data.antidectProfile || '';
		this.fbDomainVerification = data.fbDomainVerification || '';
	}
}

export default SubDomain;
