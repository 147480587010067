import actions from '../constants/reduxActions';
import ClientModel from '../models/client.model';

const initialState = {
	items: {},
};

export default function(state = initialState, action) {
	switch (action.type) {
		case actions.clients.item.set:
			const clientModel = new ClientModel(action.payload.data);
			return {
				...state,
				items: { ...state.items, [clientModel.id]: clientModel },
			};
		default:
			return state;
	}
}
