import { combineReducers } from 'redux';

import actions from '../constants/reduxActions';
// Reducers
import auth from './auth';
import currency from './currency';
import landings from './landings';
import countries from './countries';
import application from './application';
import users from './users';
import domains from './domains';
import clients from './clients';
import subDomains from './subDomains';
import geoGroup from './geoGroup';
import subGroups from './subGroups';
import groups from './groups';
import redirectDomains from './redirectDomains';
import geo from './geo';
import redirectSubDomains from './redirectSubDomains';
import preLandings from './preLandings';

const appReducer = combineReducers({
	auth,
	landings,
	preLandings,
	currency,
	countries,
	application,
	users,
	domains,
	clients,
	geoGroup,
	geo,
	subDomains,
	redirectDomains,
	redirectSubDomains,
	subGroups,
	groups,
});

const rootReducer = (state, action) => {
	if (action.type === actions.auth.logout) {
		const leadColumns = localStorage.getItem('leadColumns');
		localStorage.clear();
		if (leadColumns !== null && leadColumns !== 'null')
			localStorage.setItem('leadColumns', leadColumns);
		state = {};
	}

	return appReducer(state, action);
};

export default rootReducer;
