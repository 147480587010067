import Base from './base.model';

class RedirectDomainModel extends Base {
	constructor(data) {
		super();
		data = data || {};

		this.id = data.id || '';
		this.name = data.name || '';
		this.user = data.user || '';
	}
}

export default RedirectDomainModel;
