import React from 'react';

import { connect } from 'react-redux';

class Footer extends React.Component {
	render() {
		return (
			<footer className="main-footer">
			</footer>
		);
	}
}

export default connect(({ application: { version } }) => ({ version }))(Footer);
