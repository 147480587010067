import actions from '../constants/reduxActions';

const initialState = {
	version: '',
};

export default function(state = initialState, action) {
	switch (action.type) {
		case actions.application.version.set:
			return {
				...state,
				version: action.payload.version,
			};
		default:
			return state;
	}
}
