class Base {
	formatDate = date => {
		if (Boolean(date) === false) return '';

		const formatDate = date => {
			return date < 10 ? '0' + date : date;
		};

		date = new Date(date);

		return `${date.getFullYear()}-${formatDate(
			date.getMonth() + 1,
		)}-${formatDate(date.getDate())}, ${formatDate(
			date.getHours(),
		)}:${formatDate(date.getMinutes())}:${formatDate(date.getSeconds())}`;
	};
}

export default Base;
