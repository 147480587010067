import React, { lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router';

import Loader from '../Loader';
import AuthRoute from './AuthRoute';
import GuestRoute from './GuestRoute';
import {
	LOCAL_LOGIN,
	LOCAL_GROUPS,
	LOCAL_BILLING,
	LOCAL_OFFICES_EXPENSES,
	LOCAL_USER_EDIT,
	LOCAL_DASHBOARD,
	LOCAL_USERS_LIST,
	LOCAL_FORM_LIST,
	LOCAL_FORM_CREATE,
	LOCAL_FORM_EDIT,
	LOCAL_LEADS_LIST,
	LOCAL_LEAD_EDIT,
	LOCAL_USER_CREATE,
	LOCAL_DOUBLE_LIST,
	LOCAL_DOMAIN_EDIT,
	LOCAL_CLIENTS_LIST,
	LOCAL_CLIENTS_EDIT,
	LOCAL_DOMAINS_LIST,
	LOCAL_BLACKLIST_IP,
	LOCAL_LANDINGS_EDIT,
	LOCAL_LANDINGS_LIST,
	LOCAL_DOMAIN_CREATE,
	LOCAL_STATISTICS_CPL,
	LOCAL_PASSWORD_RESET,
	LOCAL_PASSWORD_FORGOT,
	LOCAL_SUB_DOMAIN_EDIT,
	LOCAL_LANDINGS_CREATE,
	LOCAL_SUB_DOMAINS_LIST,
	LOCAL_AUTO_RESEND_LIST,
	LOCAL_SUB_DOMAIN_CREATE,
	LOCAL_LOGS_LIST,
	LOCAL_APPS_LIST,
	LOCAL_STATUSES_LIST,
	LOCAL_STATUSES_CREATE,
	LOCAL_STATUSES_EDIT,
	LOCAL_CARDS_LIST,
	LOCAL_CARDS_CREATE,
	LOCAL_CARDS_EDIT,
	LOCAL_GEO_LIST,
	LOCAL_GEO_CREATE,
	LOCAL_GEO_EDIT,
	LOCAL_BLACKLIST_IP_EDIT,
	LOCAL_STATISTICS_GROUPS,
	LOCAL_PRE_LANDINGS_EDIT,
	LOCAL_PRE_LANDINGS_LIST,
	LOCAL_POST_LANDINGS_CREATE,
	LOCAL_POST_LANDINGS_EDIT,
	LOCAL_POST_LANDINGS_LIST,
	LOCAL_DEMONETIZATIONS_CREATE,
	LOCAL_DEMONETIZATIONS_EDIT,
	LOCAL_DEMONETIZATIONS_LIST,
	LOCAL_APPS_EDIT,
	LOCAL_STATISTICS_GENERAL,
	LOCAL_BLACKLIST_IP_CREATE,
	LOCAL_PRE_LANDINGS_CREATE,
	LOCAL_APPS_CREATE,
	LOCAL_STATISTICS_DEPOSITS_GEOLOCATIONS,
	LOCAL_STATISTICS_DEPOSITS_USERS,
	LOCAL_STATISTICS_DEPOSITS_LINKS,
	LOCAL_STATISTICS_CALCULATIONS_SALARY,
	LOCAL_STATISTICS_DEPOSITS_CLIENTS_GEOLOCATIONS,
	LOCAL_STATISTICS_PAYMENT_TYPE_CPL,
	LOCAL_STATISTICS_DEPOSITS_CLIENTS_UNIFIED_STATUS,
	LOCAL_STATISTICS_DEPOSITS_LANDINGS,
	LOCAL_STATISTICS_DEPOSITS_TEAM_LEADS,
	LOCAL_STATISTICS_DEPOSITS_COUNTRIES,
	LOCAL_STATISTICS_DEPOSITS_PRE_LANDINGS,
	LOCAL_BILLING_CREATE,
	LOCAL_OFFICES_EXPENSES_CREATE,
	LOCAL_STATISTICS_CLIENT_HISTORY,
	LOCAL_STATISTICS_DEPOSITS_CLIENTS_COUNTRIES,
	LOCAL_COUNTRIES_BLACKLIST_LIST,
	LOCAL_COUNTRIES_BLACKLIST_CREATE,
	LOCAL_COUNTRIES_BLACKLIST_EDIT,
	LOCAL_OFFICES_EXPENSES_TECH_SPEND,
	LOCAL_LEAD_ARCHIVE,
	LOCAL_BILLING_UPDATE,
	LOCAL_BILLING_UPLOAD,
	LOCAL_OFFICES_EXPENSES_UPDATE,
	LOCAL_GROUPS_EDIT,
	LOCAL_SUB_GROUPS_CREATE,
	LOCAL_GROUPS_CREATE,
	LOCAL_SUB_GROUPS_EDIT,
	LOCAL_STATISTICS_DEPOSITS_CONSTRUCTOR,
	LOCAL_STATISTICS_DEPOSITS_GEO,
	LOCAL_CPL_RULE_LIST,
	LOCAL_TOTAL_CAP_LIST,
	LOCAL_TOTAL_CAP_CREATE,
	LOCAL_TOTAL_CAP_EDIT,
	LOCAL_BINOMS_LIST,
	LOCAL_BINOMS_CREATE,
	LOCAL_BINOMS_EDIT,
	LOCAL_SUB_GROUP_CAP_CREATE,
	LOCAL_SUB_GROUP_CAP_LIST,
	LOCAL_SUB_GROUP_CAP_EDIT,
	LOCAL_COUNTRIES_LIMIT_LIST,
	LOCAL_COUNTRIES_LIMIT_CREATE,
	LOCAL_COUNTRIES_LIMIT_EDIT,
	LOCAL_CURRENCY_CREATE,
	LOCAL_CURRENCY_EDIT,
	LOCAL_CURRENCY_LIST,
	LOCAL_REDIRECT_DOMAINS_LIST,
	LOCAL_REDIRECT_DOMAINS_CREATE,
	LOCAL_REDIRECT_DOMAINS_EDIT,
	LOCAL_GROUP_CAP_LIST,
	LOCAL_GROUP_CAP_CREATE,
	LOCAL_GROUP_CAP_EDIT,
	LOCAL_STATUSES_NEW_STATUS,
	LOCAL_REDIRECT_SUB_DOMAINS_LIST,
	LOCAL_REDIRECT_SUB_DOMAINS_CREATE,
	LOCAL_REDIRECT_SUB_DOMAINS_EDIT,
	LOCAL_BLACKLIST_CONTENT_URLS_LIST,
	LOCAL_BLACKLIST_CONTENT_URLS_EDIT,
	LOCAL_BLACKLIST_CONTENT_URLS_CREATE,
	LOCAL_SNAPSHOTS_LIST,
	LOCAL_SNAPSHOTS_GET,
} from '../../constants/urls';

import {
	ADMIN,
	ANALYTIC,
	MARKUPER,
	TEAM_LEAD,
	MANAGER,
	AFFILIATE,
	WORKER,
	ACCOUNTANT,
	SUPPORT,
	TECH_ADMIN,
	ADMIN_CUT,
} from '../../constants/roles';

class Routes extends React.Component {
	render() {
		const { isAuth } = this.props;

		return (
			<Suspense fallback={<Loader />}>
				<Switch>
					<AuthRoute
						exact
						path={LOCAL_DASHBOARD}
						isAuth={isAuth}
						component={lazy(() => import('../Dashboard'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_LEADS_LIST}
						isAuth={isAuth && !this.checkRole([MARKUPER])}
						component={lazy(() => import('../Leads'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_LEAD_ARCHIVE}
						isAuth={isAuth && this.checkRole([ADMIN, ADMIN_CUT])}
						component={lazy(() => import('../Leads/archive'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_LEAD_EDIT}
						isAuth={isAuth && this.checkRole([ADMIN, ADMIN_CUT])}
						component={lazy(() => import('../Leads/edit'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_AUTO_RESEND_LIST}
						isAuth={isAuth && this.checkRole([ADMIN])}
						component={lazy(() => import('../AutoResend'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_DOUBLE_LIST}
						isAuth={isAuth && !this.checkRole([MARKUPER, ANALYTIC])}
						component={lazy(() => import('../Doubles'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_DOMAINS_LIST}
						isAuth={isAuth && !this.checkRole([ANALYTIC, AFFILIATE])}
						component={lazy(() => import('../Domains'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_DOMAIN_CREATE}
						isAuth={isAuth && !this.checkRole([ANALYTIC, AFFILIATE])}
						component={lazy(() => import('../Domains/create'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_DOMAIN_EDIT}
						isAuth={isAuth && !this.checkRole([ANALYTIC, AFFILIATE])}
						component={lazy(() => import('../Domains/edit'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_FORM_LIST}
						isAuth={isAuth && this.checkRole([ADMIN, MARKUPER])}
						component={lazy(() => import('../Forms'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_FORM_CREATE}
						isAuth={isAuth && this.checkRole([ADMIN, MARKUPER])}
						component={lazy(() => import('../Forms/create'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_FORM_EDIT}
						isAuth={isAuth && this.checkRole([ADMIN, MARKUPER])}
						component={lazy(() => import('../Forms/edit'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_SUB_DOMAINS_LIST}
						isAuth={isAuth && !this.checkRole([ANALYTIC, AFFILIATE])}
						component={lazy(() => import('../SubDomains'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_SUB_DOMAIN_CREATE}
						isAuth={isAuth && !this.checkRole([ANALYTIC, AFFILIATE])}
						component={lazy(() => import('../SubDomains/create'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_SUB_DOMAIN_EDIT}
						isAuth={isAuth && !this.checkRole([ANALYTIC, AFFILIATE])}
						component={lazy(() => import('../SubDomains/edit'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_SNAPSHOTS_LIST}
						isAuth={isAuth && this.checkRole([ADMIN])}
						component={lazy(() => import('../Snapshots'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_SNAPSHOTS_GET}
						isAuth={isAuth && this.checkRole([ADMIN])}
						component={lazy(() => import('../Snapshots/items'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_APPS_LIST}
						isAuth={isAuth && !this.checkRole([ANALYTIC, AFFILIATE])}
						component={lazy(() => import('../Apps'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_APPS_CREATE}
						isAuth={isAuth && !this.checkRole([ANALYTIC, AFFILIATE])}
						component={lazy(() => import('../Apps/create'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_APPS_EDIT}
						isAuth={isAuth && !this.checkRole([ANALYTIC, AFFILIATE])}
						component={lazy(() => import('../Apps/edit'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_CURRENCY_LIST}
						isAuth={isAuth && this.checkRole([ADMIN])}
						component={lazy(() => import('../Currency'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_CURRENCY_CREATE}
						isAuth={isAuth && this.checkRole([ADMIN])}
						component={lazy(() => import('../Currency/create'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_CURRENCY_EDIT}
						isAuth={isAuth && this.checkRole([ADMIN])}
						component={lazy(() => import('../Currency/edit'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_BINOMS_LIST}
						isAuth={isAuth && !this.checkRole([ANALYTIC, AFFILIATE])}
						component={lazy(() => import('../Binoms'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_BINOMS_CREATE}
						isAuth={isAuth && !this.checkRole([ANALYTIC, AFFILIATE])}
						component={lazy(() => import('../Binoms/create'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_BINOMS_EDIT}
						isAuth={isAuth && !this.checkRole([ANALYTIC, AFFILIATE])}
						component={lazy(() => import('../Binoms/edit'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_REDIRECT_DOMAINS_LIST}
						isAuth={isAuth && !this.checkRole([ANALYTIC, AFFILIATE])}
						component={lazy(() => import('../RedirectDomains'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_REDIRECT_DOMAINS_CREATE}
						isAuth={isAuth && !this.checkRole([ANALYTIC, AFFILIATE])}
						component={lazy(() => import('../RedirectDomains/create'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_REDIRECT_DOMAINS_EDIT}
						isAuth={isAuth && !this.checkRole([ANALYTIC, AFFILIATE])}
						component={lazy(() => import('../RedirectDomains/edit'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_REDIRECT_SUB_DOMAINS_LIST}
						isAuth={isAuth && !this.checkRole([ANALYTIC, AFFILIATE])}
						component={lazy(() => import('../RedirectSubDomains'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_REDIRECT_SUB_DOMAINS_CREATE}
						isAuth={isAuth && !this.checkRole([ANALYTIC, AFFILIATE])}
						component={lazy(() => import('../RedirectSubDomains/create'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_REDIRECT_SUB_DOMAINS_EDIT}
						isAuth={isAuth && !this.checkRole([ANALYTIC, AFFILIATE])}
						component={lazy(() => import('../RedirectSubDomains/edit'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_TOTAL_CAP_LIST}
						isAuth={isAuth && this.checkRole([ADMIN])}
						component={lazy(() => import('../TotalCap'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_TOTAL_CAP_CREATE}
						isAuth={isAuth && this.checkRole([ADMIN])}
						component={lazy(() => import('../TotalCap/create'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_TOTAL_CAP_EDIT}
						isAuth={isAuth && this.checkRole([ADMIN])}
						component={lazy(() => import('../TotalCap/edit'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_SUB_GROUP_CAP_LIST}
						isAuth={isAuth && this.checkRole([ADMIN])}
						component={lazy(() => import('../SubGroupCap'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_SUB_GROUP_CAP_CREATE}
						isAuth={isAuth && this.checkRole([ADMIN])}
						component={lazy(() => import('../SubGroupCap/create'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_SUB_GROUP_CAP_EDIT}
						isAuth={isAuth && this.checkRole([ADMIN])}
						component={lazy(() => import('../SubGroupCap/edit'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_GROUP_CAP_LIST}
						isAuth={isAuth && this.checkRole([ADMIN])}
						component={lazy(() => import('../GroupCap'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_GROUP_CAP_CREATE}
						isAuth={isAuth && this.checkRole([ADMIN])}
						component={lazy(() => import('../GroupCap/create'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_GROUP_CAP_EDIT}
						isAuth={isAuth && this.checkRole([ADMIN])}
						component={lazy(() => import('../GroupCap/edit'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_COUNTRIES_LIMIT_LIST}
						isAuth={isAuth && this.checkRole([ADMIN])}
						component={lazy(() => import('../CountriesLimit'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_COUNTRIES_LIMIT_CREATE}
						isAuth={isAuth && this.checkRole([ADMIN])}
						component={lazy(() => import('../CountriesLimit/create'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_COUNTRIES_LIMIT_EDIT}
						isAuth={isAuth && this.checkRole([ADMIN])}
						component={lazy(() => import('../CountriesLimit/edit'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_STATUSES_LIST}
						isAuth={isAuth && this.checkRole([ADMIN, TECH_ADMIN])}
						component={lazy(() => import('../Statuses'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_STATUSES_NEW_STATUS}
						isAuth={isAuth && this.checkRole([ADMIN, TECH_ADMIN])}
						component={lazy(() => import('../Statuses/newStatus'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_STATUSES_CREATE}
						isAuth={isAuth && this.checkRole([ADMIN, TECH_ADMIN])}
						component={lazy(() => import('../Statuses/create'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_STATUSES_EDIT}
						isAuth={isAuth && this.checkRole([ADMIN, TECH_ADMIN])}
						component={lazy(() => import('../Statuses/edit'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_CARDS_LIST}
						isAuth
						component={lazy(() => import('../Cards'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_CARDS_CREATE}
						isAuth
						component={lazy(() => import('../Cards/create'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_CARDS_EDIT}
						isAuth
						component={lazy(() => import('../Cards/edit'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_GEO_LIST}
						isAuth={isAuth && this.checkRole([ADMIN, TECH_ADMIN])}
						component={lazy(() => import('../Geolocations'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_GEO_CREATE}
						isAuth={isAuth && this.checkRole([ADMIN, TECH_ADMIN])}
						component={lazy(() => import('../Geolocations/create'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_GEO_EDIT}
						isAuth={isAuth && this.checkRole([ADMIN, TECH_ADMIN])}
						component={lazy(() => import('../Geolocations/edit'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_CLIENTS_LIST}
						isAuth={isAuth && this.checkRole([ADMIN, SUPPORT])}
						component={lazy(() => import('../Clients'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_CLIENTS_EDIT}
						isAuth={isAuth && this.checkRole([ADMIN])}
						component={lazy(() => import('../Clients/edit'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_USERS_LIST}
						isAuth={
							isAuth &&
							this.checkRole([
								ADMIN,
								ADMIN_CUT,
								MARKUPER,
								TEAM_LEAD,
								ANALYTIC,
								MANAGER,
								TECH_ADMIN,
							])
						}
						component={lazy(() => import('../Users'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_USER_CREATE}
						isAuth={isAuth && this.checkRole([ADMIN, TECH_ADMIN])}
						component={lazy(() => import('../Users/create'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_USER_EDIT}
						isAuth={isAuth && this.checkRole([ADMIN, TECH_ADMIN])}
						component={lazy(() => import('../Users/edit'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_COUNTRIES_BLACKLIST_LIST}
						isAuth={
							isAuth && this.checkRole([ADMIN, ADMIN_CUT, TECH_ADMIN, MARKUPER])
						}
						component={lazy(() => import('../CountriesBlacklist'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_COUNTRIES_BLACKLIST_CREATE}
						isAuth={
							isAuth && this.checkRole([ADMIN, ADMIN_CUT, TECH_ADMIN, MARKUPER])
						}
						component={lazy(() => import('../CountriesBlacklist/create'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_COUNTRIES_BLACKLIST_EDIT}
						isAuth={
							isAuth && this.checkRole([ADMIN, ADMIN_CUT, TECH_ADMIN, MARKUPER])
						}
						component={lazy(() => import('../CountriesBlacklist/edit'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_BLACKLIST_CONTENT_URLS_LIST}
						isAuth={isAuth && this.checkRole([ADMIN])}
						component={lazy(() => import('../BlacklistContentUrl'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_BLACKLIST_CONTENT_URLS_CREATE}
						isAuth={isAuth && this.checkRole([ADMIN])}
						component={lazy(() => import('../BlacklistContentUrl/create'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_BLACKLIST_CONTENT_URLS_EDIT}
						isAuth={isAuth && this.checkRole([ADMIN])}
						component={lazy(() => import('../BlacklistContentUrl/edit'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_BILLING}
						isAuth={
							isAuth &&
							this.checkRole([
								ADMIN,
								ADMIN_CUT,
								ACCOUNTANT,
								WORKER,
								TEAM_LEAD,
								TECH_ADMIN,
							])
						}
						component={lazy(() => import('../Billing'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_BILLING_CREATE}
						isAuth={
							isAuth &&
							this.checkRole([
								ADMIN,
								ADMIN_CUT,
								ACCOUNTANT,
								WORKER,
								TEAM_LEAD,
								TECH_ADMIN,
							])
						}
						component={lazy(() => import('../Billing/create'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_BILLING_UPLOAD}
						isAuth={
							isAuth &&
							this.checkRole([ADMIN, ADMIN_CUT, ACCOUNTANT, TECH_ADMIN])
						}
						component={lazy(() => import('../Billing/upload'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_BILLING_UPDATE}
						isAuth={
							isAuth &&
							this.checkRole([ADMIN, ADMIN_CUT, ACCOUNTANT, TECH_ADMIN])
						}
						component={lazy(() => import('../Billing/edit'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_OFFICES_EXPENSES}
						isAuth={isAuth && this.checkRole([ADMIN, ACCOUNTANT, TECH_ADMIN])}
						component={lazy(() => import('../OfficesExpenses'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_OFFICES_EXPENSES_CREATE}
						isAuth={isAuth && this.checkRole([ADMIN, ACCOUNTANT, TECH_ADMIN])}
						component={lazy(() => import('../OfficesExpenses/create'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_OFFICES_EXPENSES_UPDATE}
						isAuth={isAuth && this.checkRole([ADMIN, ACCOUNTANT, TECH_ADMIN])}
						component={lazy(() => import('../OfficesExpenses/edit'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_OFFICES_EXPENSES_TECH_SPEND}
						isAuth={isAuth && this.checkRole([ADMIN, ACCOUNTANT, TECH_ADMIN])}
						component={lazy(() => import('../Statistics/TechSpend'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_STATISTICS_GENERAL}
						isAuth={
							isAuth &&
							this.checkRole([
								ADMIN,
								ADMIN_CUT,
								TEAM_LEAD,
								MANAGER,
								SUPPORT,
								TECH_ADMIN,
							])
						}
						component={lazy(() => import('../Statistics/General'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_STATISTICS_DEPOSITS_CONSTRUCTOR}
						isAuth={isAuth && this.checkRole([ADMIN, ADMIN_CUT, SUPPORT])}
						component={lazy(() => import('../Statistics/Constructor'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_LOGS_LIST}
						isAuth={isAuth && this.checkRole([ADMIN])}
						component={lazy(() => import('../Logs'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_STATISTICS_CPL}
						isAuth={
							isAuth &&
							this.checkRole([
								ADMIN,
								ADMIN_CUT,
								TEAM_LEAD,
								ANALYTIC,
								MANAGER,
								ACCOUNTANT,
								TECH_ADMIN,
								WORKER,
							])
						}
						component={lazy(() => import('../Statistics/CPL'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_STATISTICS_DEPOSITS_USERS}
						isAuth={
							isAuth &&
							this.checkRole([
								ADMIN,
								ADMIN_CUT,
								MANAGER,
								TEAM_LEAD,
								WORKER,
								SUPPORT,
								TECH_ADMIN,
								ACCOUNTANT,
							])
						}
						component={lazy(() => import('../Statistics/UsersDeposits'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_STATISTICS_DEPOSITS_GEO}
						isAuth={
							isAuth &&
							this.checkRole([
								ADMIN,
								ADMIN_CUT,
								MANAGER,
								TEAM_LEAD,
								WORKER,
								SUPPORT,
								TECH_ADMIN,
								ACCOUNTANT,
							])
						}
						component={lazy(() => import('../Statistics/DepositsGeo'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_STATISTICS_CLIENT_HISTORY}
						isAuth={isAuth && this.checkRole([ADMIN])}
						component={lazy(() =>
							import('../Statistics/ClientFailsStatistics'),
						)}
					/>
					<AuthRoute
						exact
						path={LOCAL_STATISTICS_DEPOSITS_CLIENTS_GEOLOCATIONS}
						isAuth={
							isAuth && this.checkRole([ADMIN, ADMIN_CUT, SUPPORT, TECH_ADMIN])
						}
						component={lazy(() => import('../Statistics/ClientsDeposits'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_STATISTICS_PAYMENT_TYPE_CPL}
						isAuth={
							isAuth &&
							this.checkRole([
								ADMIN,
								ADMIN_CUT,
								ACCOUNTANT,
								TEAM_LEAD,
								ANALYTIC,
								MANAGER,
								ACCOUNTANT,
								TECH_ADMIN,
							])
						}
						component={lazy(() => import('../Statistics/PaymentTypeCpl'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_STATISTICS_DEPOSITS_CLIENTS_UNIFIED_STATUS}
						isAuth={
							isAuth && this.checkRole([ADMIN, ADMIN_CUT, SUPPORT, TECH_ADMIN])
						}
						component={lazy(() => import('../Statistics/ClientsUnifiedStatus'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_STATISTICS_DEPOSITS_CLIENTS_COUNTRIES}
						isAuth={
							isAuth && this.checkRole([ADMIN, ADMIN_CUT, SUPPORT, TECH_ADMIN])
						}
						component={lazy(() =>
							import('../Statistics/ClientsCountriesDeposits'),
						)}
					/>
					<AuthRoute
						exact
						path={LOCAL_STATISTICS_DEPOSITS_LANDINGS}
						isAuth={
							isAuth &&
							this.checkRole([ADMIN, ANALYTIC, MANAGER, SUPPORT, TECH_ADMIN])
						}
						component={lazy(() => import('../Statistics/LandingsDeposits'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_STATISTICS_DEPOSITS_PRE_LANDINGS}
						isAuth={
							isAuth &&
							this.checkRole([
								ADMIN,
								ADMIN_CUT,
								ANALYTIC,
								MANAGER,
								SUPPORT,
								TECH_ADMIN,
							])
						}
						component={lazy(() => import('../Statistics/PreLandingsDeposits'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_STATISTICS_DEPOSITS_GEOLOCATIONS}
						isAuth={
							isAuth &&
							this.checkRole([ADMIN, ADMIN_CUT, MANAGER, SUPPORT, TECH_ADMIN])
						}
						component={lazy(() => import('../Statistics/GeoDeposits'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_STATISTICS_DEPOSITS_COUNTRIES}
						isAuth={
							isAuth && this.checkRole([ADMIN, MANAGER, SUPPORT, TECH_ADMIN])
						}
						component={lazy(() => import('../Statistics/DirectionsDeposits'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_STATISTICS_DEPOSITS_TEAM_LEADS}
						isAuth={
							isAuth &&
							this.checkRole([ADMIN, ADMIN_CUT, MANAGER, SUPPORT, TECH_ADMIN])
						}
						component={lazy(() => import('../Statistics/TeamLeadDeposits'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_STATISTICS_DEPOSITS_LINKS}
						isAuth={
							isAuth &&
							this.checkRole([
								ADMIN,
								ADMIN_CUT,
								ANALYTIC,
								TEAM_LEAD,
								MANAGER,
								SUPPORT,
								TECH_ADMIN,
								WORKER,
								TEAM_LEAD,
							])
						}
						component={lazy(() => import('../Statistics/LinksDeposits'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_STATISTICS_CALCULATIONS_SALARY}
						isAuth={isAuth && this.checkRole([ADMIN, ACCOUNTANT])}
						component={lazy(() => import('../Statistics/CalculationsSalary'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_STATISTICS_GROUPS}
						isAuth={isAuth && this.checkRole([ADMIN, SUPPORT])}
						component={lazy(() => import('../Statistics/Groups'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_LANDINGS_LIST}
						isAuth={
							isAuth && this.checkRole([MARKUPER, ADMIN_CUT, ADMIN, TECH_ADMIN])
						}
						component={lazy(() => import('../Landings'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_LANDINGS_CREATE}
						isAuth={isAuth && this.checkRole([MARKUPER, ADMIN, TECH_ADMIN])}
						component={lazy(() => import('../Landings/create'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_LANDINGS_EDIT}
						isAuth={isAuth && this.checkRole([MARKUPER, ADMIN, TECH_ADMIN])}
						component={lazy(() => import('../Landings/edit'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_PRE_LANDINGS_LIST}
						isAuth={
							isAuth && this.checkRole([MARKUPER, ADMIN_CUT, ADMIN, TECH_ADMIN])
						}
						component={lazy(() => import('../PreLandings'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_PRE_LANDINGS_CREATE}
						isAuth={isAuth && this.checkRole([MARKUPER, ADMIN, TECH_ADMIN])}
						component={lazy(() => import('../PreLandings/create'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_PRE_LANDINGS_EDIT}
						isAuth={isAuth && this.checkRole([MARKUPER, ADMIN, TECH_ADMIN])}
						component={lazy(() => import('../PreLandings/edit'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_POST_LANDINGS_LIST}
						isAuth={
							isAuth && this.checkRole([MARKUPER, ADMIN_CUT, ADMIN, TECH_ADMIN])
						}
						component={lazy(() => import('../PostLandings'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_POST_LANDINGS_CREATE}
						isAuth={isAuth && this.checkRole([MARKUPER, ADMIN, TECH_ADMIN])}
						component={lazy(() => import('../PostLandings/create'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_POST_LANDINGS_EDIT}
						isAuth={isAuth && this.checkRole([MARKUPER, ADMIN, TECH_ADMIN])}
						component={lazy(() => import('../PostLandings/edit'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_DEMONETIZATIONS_LIST}
						isAuth={
							isAuth &&
							this.checkRole([MARKUPER, ADMIN_CUT, ADMIN, SUPPORT, TECH_ADMIN])
						}
						component={lazy(() => import('../Demonetizations'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_DEMONETIZATIONS_CREATE}
						isAuth={isAuth && this.checkRole([MARKUPER, ADMIN, TECH_ADMIN])}
						component={lazy(() => import('../Demonetizations/create'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_DEMONETIZATIONS_EDIT}
						isAuth={isAuth && this.checkRole([MARKUPER, ADMIN, TECH_ADMIN])}
						component={lazy(() => import('../Demonetizations/edit'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_BLACKLIST_IP}
						isAuth={
							isAuth &&
							this.checkRole([ADMIN, ADMIN_CUT, MANAGER, TECH_ADMIN, MARKUPER])
						}
						component={lazy(() => import('../BlacklistIP'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_BLACKLIST_IP_CREATE}
						isAuth={
							isAuth &&
							this.checkRole([ADMIN, ADMIN_CUT, MANAGER, TECH_ADMIN, MARKUPER])
						}
						component={lazy(() => import('../BlacklistIP/create'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_BLACKLIST_IP_EDIT}
						isAuth={
							isAuth &&
							this.checkRole([ADMIN, ADMIN_CUT, MANAGER, TECH_ADMIN, MARKUPER])
						}
						component={lazy(() => import('../BlacklistIP/edit'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_GROUPS}
						isAuth={isAuth && this.checkRole([ADMIN, TECH_ADMIN])}
						component={lazy(() => import('../Groups'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_GROUPS_CREATE}
						isAuth={isAuth && this.checkRole([ADMIN])}
						component={lazy(() => import('../Groups/create'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_GROUPS_EDIT}
						isAuth={isAuth && this.checkRole([ADMIN])}
						component={lazy(() => import('../Groups/edit'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_SUB_GROUPS_CREATE}
						isAuth={isAuth && this.checkRole([ADMIN, SUPPORT])}
						component={lazy(() => import('../Groups/subGroupsCreate'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_CPL_RULE_LIST}
						isAuth={isAuth && this.checkRole([ADMIN])}
						component={lazy(() => import('../CplRules'))}
					/>
					<AuthRoute
						exact
						path={LOCAL_SUB_GROUPS_EDIT}
						isAuth={isAuth && this.checkRole([ADMIN, SUPPORT])}
						component={lazy(() => import('../Groups/subGroupsEdit'))}
					/>
					<GuestRoute
						exact
						path={LOCAL_LOGIN}
						isAuth={isAuth}
						component={lazy(() => import('../Login'))}
					/>
					<GuestRoute
						exact
						path={LOCAL_PASSWORD_FORGOT}
						isAuth={isAuth}
						component={lazy(() => import('../PasswordForgot'))}
					/>
					<GuestRoute
						exact
						path={LOCAL_PASSWORD_RESET}
						isAuth={isAuth}
						component={lazy(() => import('../PasswordReset'))}
					/>
					<Route exact component={lazy(() => import('../NotFound'))} />
				</Switch>
			</Suspense>
		);
	}

	checkScope = (scopeName = '', scopeValue = []) => {
		const { access } = this.props;

		return (
			scopeName in access &&
			Boolean(
				scopeValue.filter(value => access[scopeName].includes(value)).length,
			)
		);
	};

	checkRole = (roles = []) => {
		return Boolean(~roles.indexOf(this.props.role));
	};
}

export default connect(({ auth: { isAuth, access, role } }) => ({
	isAuth,
	access,
	role,
}))(Routes);
