import actions from '../constants/reduxActions';
import SubGroupModel from '../models/subGroup.model';

const initialState = {
	items: {},
};

export default function(state = initialState, action) {
	switch (action.type) {
		case actions.subGroups.item.set:
			const subGroupModel = new SubGroupModel(action.payload.data);
			return {
				...state,
				items: { ...state.items, [subGroupModel.id]: subGroupModel },
			};
		default:
			return state;
	}
}
