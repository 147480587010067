import actions from '../constants/reduxActions';

const initialState = {
	items: {},
};

export default function(state = initialState, action) {
	switch (action.type) {
		case actions.geoGroup.item.set:
			const geoGroupModel = action.payload.data;
			return {
				...state,
				items: { ...state.items, [geoGroupModel.id]: geoGroupModel },
			};
		default:
			return state;
	}
}
