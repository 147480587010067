import actions from '../constants/reduxActions';
import LandingModel from '../models/landing.model';

const initialState = {
	items: [],
	geolocation: [],
	landings: {},
};

export default function(state = initialState, action) {
	switch (action.type) {
		case actions.landings.geolocation.set:
			return {
				...state,
				geolocation: action.payload.items,
			};
		case actions.landings.list.set:
			return {
				...state,
				items: action.payload.items.map(landing => new LandingModel(landing)),
			};
		case actions.landings.item.set:
			const landingModel = new LandingModel(action.payload.data);
			return {
				...state,
				landings: { ...state.landings, [landingModel.id]: landingModel },
			};
		default:
			return state;
	}
}
