import React from 'react';
import { Redirect, Route } from 'react-router';

import Header from '../Header';
import Footer from '../Footer';
import Sidebar from '../Sidebar';
import { LOCAL_LOGIN } from '../../constants/urls';

const AuthRoute = ({ component: Component, isAuth, navigation, ...rest }) => (
	<Route
		{...rest}
		render={props =>
			isAuth === true ? (
				<>
					<Header />
					<Sidebar />
					<Component {...props} />
					<Footer />
				</>
			) : (
				<Redirect to={LOCAL_LOGIN} />
			)
		}
	/>
);

export default AuthRoute;
