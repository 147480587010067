import actions from '../constants/reduxActions';
import RedirectDomainModel from '../models/redirectDomain.model';

const initialState = {
	items: {},
};

export default function(state = initialState, action) {
	switch (action.type) {
		case actions.redirectDomains.item.set:
			const redirectDomainModel = new RedirectDomainModel(action.payload.data);
			return {
				...state,
				items: {
					...state.items,
					[redirectDomainModel.id]: redirectDomainModel,
				},
			};
		default:
			return state;
	}
}
