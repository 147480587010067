export const STATUSES = {
	DELETED: 0,
	ACTIVE: 1,
	BLOCKED: 2,
};

export const STATUS_VALUE_LIST = {
	[STATUSES.DELETED]: 'Deleted',
	[STATUSES.ACTIVE]: 'Active',
	[STATUSES.BLOCKED]: 'Blocked',
};

export const OFFICES = ['', 'R', 'G', 'K1', 'K2', 'O1', 'O2', 'O3', 'W'];
// update offices also in Leads/index.jsx
