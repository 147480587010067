import axios from 'axios';

import { logout, successLogin } from '../actions/auth';
import { API_TOKEN_REFRESH, BASE_API_URL } from '../constants/urls';
import {
	ACCESS_TOKEN,
	REFRESH_TOKEN,
	TOKEN_TYPE,
} from '../constants/application';

let requests = [];
let isFetchingAccessToken = false;

export const setDefaultAxios = dispatch => {
	const clearData = () => {
		dispatch(logout());
	};

	const addSubscriber = callback => requests.push(callback);
	const waitResponseRefreshing = req => {
		return new Promise(resolve => {
			addSubscriber(() => {
				req.headers.Authorization = generateAuthToken();
				return resolve(axios(req));
			});
		});
	};
	const waitRequestRefreshing = req => {
		return new Promise(resolve => {
			addSubscriber(() => {
				req.headers.Authorization = generateAuthToken();
				return resolve(req);
			});
		});
	};

	axios.defaults.baseURL = BASE_API_URL;

	const updateUserPermissions = () => {
		dispatch(successLogin());
	};

	// Check tokens and authorize user
	(async function() {
		if (
			checkToken(ACCESS_TOKEN) === false &&
			checkToken(REFRESH_TOKEN) === false
		) {
			clearData();

			return void 0;
		}

		updateUserPermissions();
	})();

	axios.interceptors.request.use(async req => {
		if (isFetchingAccessToken) {
			if (req.url === API_TOKEN_REFRESH) {
				return req;
			}

			return waitRequestRefreshing(req);
		}

		const accessToken = localStorage.getItem(ACCESS_TOKEN);

		if (accessToken === null) return req;

		if (checkToken(ACCESS_TOKEN) === false) {
			if (checkToken(REFRESH_TOKEN) && isFetchingAccessToken === false) {
				localStorage.removeItem(ACCESS_TOKEN);
				refreshToken();
				return waitRequestRefreshing(req);
			} else {
				clearData();

				throw new Error('Invalid tokens');
			}
		}

		req.headers.Authorization = generateAuthToken();

		return req;
	});

	axios.interceptors.response.use(
		response => response,
		async error => {
			if (error.response === undefined) {
				clearData();

				return Promise.reject(error);
			}
			if (error.response.status === 401) {
				if (
					isFetchingAccessToken === false &&
					error.config.url !== `${BASE_API_URL}${API_TOKEN_REFRESH}` &&
					checkToken(REFRESH_TOKEN)
				) {
					refreshToken();
					return waitResponseRefreshing(error.config.originalRequest);
				} else {
					clearData();

					throw new Error('Invalid tokens');
				}
			}
			return Promise.reject(error);
		},
	);
};

const generateAuthToken = () =>
	`${localStorage.getItem(TOKEN_TYPE)} ${localStorage.getItem(ACCESS_TOKEN)}`;

const refreshToken = async () => {
	isFetchingAccessToken = true;
	return await axios
		.post(API_TOKEN_REFRESH, {
			refresh_token: localStorage.getItem(REFRESH_TOKEN),
		})
		.then(
			({
				data: {
					data: { access_token, refresh_token, token_type },
					status,
				},
			}) => {
				isFetchingAccessToken = false;
				if (status) {
					localStorage.setItem(ACCESS_TOKEN, access_token);
					localStorage.setItem(REFRESH_TOKEN, refresh_token);
					localStorage.setItem(TOKEN_TYPE, token_type);
				}
				onAccessTokenFetched();

				return status;
			},
		);
};

const onAccessTokenFetched = () => {
	requests = requests.filter(callback => callback());
};

const checkToken = tokenName => {
	const token = localStorage.getItem(tokenName);
	const timeNow = (Date.now() / 1000) | 0;

	return (
		token !== null &&
		timeNow < JSON.parse(atob(token.split('.')[1])).exp - 60 * 5
	);
};
