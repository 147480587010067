import Base from './base.model';

class Domain extends Base {
	constructor(data) {
		super();
		data = data || {};

		this.id = data.id || '';
		this.name = data.name || '';
		this.user = data.user || '';
		this.form = data.form || '';
		this.status = Number(data.status) || 0;
		this.landing = data.landing || '';
		this.createdAt = this.formatDate(data.createdAt);
		this.formHeader = data.form_header || '';
		this.formFooter = data.form_footer || '';
	}
}

export default Domain;
