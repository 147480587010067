import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

/**
 * internal components
 */
import MenuItem from './MenuItem';

class DropDownMenuItem extends React.Component {
	state = {
		opened: false,
		activeIndex: -1,
	};

	toggle = () => this.setState({ opened: !this.state.opened });

	onActive = (idx, active) => {
		if (active) this.setState({ activeIndex: idx });
		else if (this.state.activeIndex === idx) this.setState({ activeIndex: -1 });
	};

	render() {
		let { title, iconName, hide, items } = this.props;
		let { opened, activeIndex } = this.state;
		return (
			<li
				className={`treeview ${activeIndex >= 0 && 'active'} ${opened &&
					'menu-open'}`}
				style={{ display: hide ? 'none' : 'block' }}
			>
				<Link to="#" onClick={this.toggle}>
					{iconName && <i className={`fa ${iconName}`} aria-hidden="true" />}
					<span>{title}</span>
					<span className="pull-right-container">
						<i className="fa fa-angle-left pull-right" />
					</span>
				</Link>
				<ul
					className="treeview-menu"
					style={{ display: opened ? 'block' : 'none' }}
				>
					{items.map((item, idx) => (
						<MenuItem
							{...item}
							key={idx}
							onActive={active => this.onActive(idx, active)}
						/>
					))}
				</ul>
			</li>
		);
	}
}

DropDownMenuItem.propTypes = {
	title: PropTypes.string.isRequired,
	iconName: PropTypes.string,
	hide: PropTypes.bool,
	items: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			iconName: PropTypes.string,
			to: PropTypes.string.isRequired,
			hide: PropTypes.bool,
		}),
	).isRequired,
};

export default DropDownMenuItem;
