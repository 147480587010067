import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';

class MenuItem extends React.Component {
	active = false;

	componentDidUpdate(prevProps, prevState, snapshot) {
		let {
			location: { pathname },
			onActive,
			to,
		} = this.props;
		let active = pathname === to;
		if (this.active !== active) {
			onActive && onActive(active);
			this.active = active;
		}
	}

	render() {
		let {
			hide,
			iconName,
			location: { pathname },
			title,
			to,
		} = this.props;

		return (
			<li
				className={`treeview ${pathname === to && 'active'}`}
				style={{ display: hide ? 'none' : 'block' }}
			>
				<Link to={to}>
					{iconName && <i className={`fa ${iconName}`} aria-hidden="true" />}
					<span>{title}</span>
				</Link>
			</li>
		);
	}
}

MenuItem.propTypes = {
	title: PropTypes.string.isRequired,
	iconName: PropTypes.string,
	to: PropTypes.string.isRequired,
	hide: PropTypes.bool,
	onActive: PropTypes.func,
};

export default withRouter(MenuItem);
