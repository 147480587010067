import React from 'react';
import ReactDOM from 'react-dom';

import './style.scss';

const loaderDOMElement = document.getElementById('loader');

export default class Loader extends React.Component {
	el = document.createElement('div');

	componentDidMount() {
		loaderDOMElement.appendChild(this.el);
	}

	componentWillUnmount() {
		loaderDOMElement.removeChild(this.el);
	}

	render() {
		return ReactDOM.createPortal(
			<div className="loader__block">
				<div className="loader__element" />
			</div>,
			this.el,
		);
	}
}
