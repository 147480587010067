import React from 'react';
import { Redirect, Route } from 'react-router';

import { LOCAL_DASHBOARD } from '../../constants/urls';

const GuestRoute = ({ component: Component, isAuth, ...rest }) => (
	<Route
		{...rest}
		render={props =>
			isAuth === true ? (
				<Redirect to={LOCAL_DASHBOARD} />
			) : (
				<Component {...props} />
			)
		}
	/>
);

export default GuestRoute;
