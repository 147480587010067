import actions from '../constants/reduxActions';

const initialState = {
	items: {},
};

export default function(state = initialState, action) {
	switch (action.type) {
		case actions.geo.item.set:
			const geoModel = action.payload.data;
			return {
				...state,
				items: { ...state.items, [geoModel.id]: geoModel },
			};
		default:
			return state;
	}
}
