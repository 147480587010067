export default {
	auth: {
		login: {
			success: 'auth_login_success',
		},
		logout: 'auth_logout',
	},
	application: {
		version: {
			set: 'set_application_version',
		},
	},
	currency: {
		item: {
			set: 'currency_item_set',
			list: 'currency_item_list',
		},
	},
	countries: {
		list: {
			set: 'countries_list_set',
		},
	},
	landings: {
		list: {
			set: 'landings_list_set',
		},
		geolocation: {
			set: 'landings_geolocation_set',
		},
		item: {
			set: 'landings_item_set',
		},
	},
	preLandings: {
		item: {
			set: 'pre_landings_item_set',
		},
	},
	users: {
		item: {
			set: 'users_item_set',
		},
		leadColumns: {
			get: 'users_get_leadColumns',
		},
	},
	domains: {
		item: {
			set: 'domain_item_set',
		},
	},
	subDomains: {
		item: {
			set: 'sub_domain_item_set',
		},
	},
	redirectDomains: {
		item: {
			set: 'redirect_domain_item_set',
		},
	},
	redirectSubDomains: {
		item: {
			set: 'redirect_sub_domain_item_set',
		},
	},
	subGroups: {
		item: {
			set: 'sub_group_item_set',
		},
	},
	groups: {
		item: {
			set: 'group_item_set',
		},
	},
	clients: {
		item: {
			set: 'client_item_set',
		},
	},
	geoGroup: {
		item: {
			set: 'geo_group_item_set',
		},
	},
	geo: {
		item: {
			set: 'geo_item_set',
		},
	},
};
