export const AMOUNT_PRECISION = 2;

export const STATUSES = {
	WAITING: 1,
	APPROVED: 2,
	CANCELED: 3,
};

export const STATUSES_KEY_LIST = {
	[STATUSES.WAITING]: 'waiting',
	[STATUSES.APPROVED]: 'approved',
	[STATUSES.CANCELED]: 'canceled',
};
