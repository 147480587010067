import Base from './base.model';
import { formatDateTime } from '../helpers/functions';

class PreLanding extends Base {
	constructor(data) {
		super();
		data = data || {};

		this.id = data.id || '';
		this.name = data.name || '';
		this.path = data.path || '';
		this.frontEndDev = data.frontEndDev || '';
		this.frontKreo = data.frontKreo || '';
		this.backKreo = data.backKreo || '';
		this.createdAt =
			Boolean(data.created_at) === true
				? formatDateTime(data.created_at)
				: undefined;
	}
}

export default PreLanding;
