import actions from '../constants/reduxActions';

export const setApplicationVersion = (dispatch, version) => {
	localStorage.setItem('build v:', version);
	dispatch({
		type: actions.application.version.set,
		payload: {
			version,
		},
	});
};

export const findParentByAttr = (element, attribute, value = null) => {
	for (; element !== document; element = element.parentNode) {
		try {
			if (element.hasAttribute(attribute)) {
				if (value !== null) {
					if (element.getAttribute(attribute) === value) return element;
				} else {
					return element;
				}
			}
		} catch (e) {
			return null;
		}
	}

	return null;
};
