import actions from '../constants/reduxActions';
import { ACCESS_TOKEN } from '../constants/application';
import UserService from '../services/user.service';

const initialState = {
	isAuth: false,
	user: {},
	role: '',
	office: '',
	access: {},
};

export default function auth(state = initialState, action) {
	switch (action.type) {
		case actions.auth.login.success:
			const token = localStorage.getItem(ACCESS_TOKEN);

			const { id, email, role, office, access } = JSON.parse(atob(token.split('.')[1]));

			UserService.setLeadColumnsOnLocalStorage(role);

			return {
				user: { id, email },
				role,
				office,
				access,
				isAuth: true,
			};
		default:
			return state;
	}
}
