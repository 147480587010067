import BaseModel from './base.model';

class ConditionModel extends BaseModel {
	constructor(data) {
		super();
		data = data || {};

		this.users = data.users || [];
		this.countries = data.countries || [];
		this.geolocation = data.geolocation || [];
	}
}

export default ConditionModel;
