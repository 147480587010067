/**
 * internal services
 */
import { MESSAGE_TYPES } from '../constants/ws';
import { logout } from '../actions/auth';

export default class WebsocketService {
	static init(dispatch) {
		WebsocketService.ws = new WebSocket(
			process.env.REACT_APP_WEBSOCKET_URL || 'ws://localhost:3004',
		);

		WebsocketService.ws.onopen = () => {
			clearTimeout(WebsocketService.reconect);
			WebsocketService.tryConnect = false;
		};

		WebsocketService.ws.onmessage = message => {
			const decrMessage = JSON.parse(message.data);
			switch (decrMessage.type) {
				case MESSAGE_TYPES.LOGOUT:
					dispatch(logout());
					break;
				default:
					console.error(`Unknown type ${decrMessage.type}`);
					break;
			}
		};

		WebsocketService.ws.onclose = () => {
			if (WebsocketService.tryConnect === true) {
				return void 0;
			}
			WebsocketService.tryConnect = true;

			WebsocketService.reconect = setTimeout(() => {
				WebsocketService.tryConnect = false;
				WebsocketService.init(dispatch);
			}, 5000);
		};
	}
}
