import React from 'react';
import thunk from 'redux-thunk';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import './dist/css/bootstrap.min.css';
import './dist/css/font-awesome.css';
import './dist/css/ionicons.min.css';
import './dist/css/dataTables.bootstrap.min.css';
import './dist/css/AdminLTE.min.css';

import rootReducer from './reducers';
import Routes from './components/Routes';
import { setDefaultAxios } from './helpers/axios';
import * as serviceWorker from './serviceWorker';
import { setApplicationVersion } from './helpers/application';
import WebsocketService from './services/websocket.service';

const store = createStore(
	rootReducer,
	composeWithDevTools(applyMiddleware(thunk)),
);

setDefaultAxios(store.dispatch);
WebsocketService.init(store.dispatch);
setApplicationVersion(store.dispatch, '1.3.13');

ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter>
			<Routes />
		</BrowserRouter>
	</Provider>,
	document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
