import actions from '../constants/reduxActions';

const initialState = {
	items: {},
};

export default function(state = initialState, action) {
	switch (action.type) {
		case actions.currency.item.set:
			return {
				...state,
				items: {
					...state.items,
					[action.payload.name]: action.payload.data,
				},
			};
		default:
			return state;
	}
}
