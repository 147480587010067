import Base from './base.model';
import { STATUSES, STATUS_VALUE_LIST } from '../constants/users';

class User extends Base {
	constructor(data = {}) {
		super();
		this.id = data._id || '';
		this.name = data.name || '';
		this.role = data.role || '';
		this.showInConditions = data.show_in_conditions ?? true;
		this.email = data.email || '';
		this.status = data.status ?? 1;
		this.telegramUsername = data.telegram_username || '';
		this.geolocationTrackLimit = data.geolocation_track_limit || 0;
		this.teamLead = data.teamLead || '';
		this.landings = data.landings || [];
		this.createdAt = this.formatDate(data.createdAt);
		this.office = data.office || '';
	}

	get statusValue() {
		switch (this.status) {
			case STATUSES.DELETED:
			case STATUSES.ACTIVE:
			case STATUSES.BLOCKED:
				return STATUS_VALUE_LIST[this.status];
			default:
				return 'undefined';
		}
	}
}

export default User;
