import actions from '../constants/reduxActions';
import DomainModel from '../models/domain.model';

const initialState = {
	items: {},
};

export default function(state = initialState, action) {
	switch (action.type) {
		case actions.domains.item.set:
			const domainModel = new DomainModel(action.payload.data);
			return {
				...state,
				items: { ...state.items, [domainModel.id]: domainModel },
			};
		default:
			return state;
	}
}
