export const STATUS_KEY_LIST = {
	HIDE: 0,
	STOPPED: 1,
	PROCESSING: 2,
};

export const STATUS_VALUE_LIST = {
	[STATUS_KEY_LIST.HIDE]: 'Hide',
	[STATUS_KEY_LIST.STOPPED]: 'Stopped',
	[STATUS_KEY_LIST.PROCESSING]: 'Processing',
};

// export const CARD_SYSTEM_TYPES = ['K', 'S', 'D', 'W', 'L', 'UA'];
export const CARD_SYSTEM_TYPES = [
	'K',
	'S',
	'D',
	'W',
	'L',
	'UA',
	'DG',
	'TRX',
	'BTC',
	'ETH',
	'S (gbp)',
	'TRC 20',
	'ERC 20',
	'EPN',
	'B',
	'ANB',
	'LA',
	'WLSTR',
	'Agency',
	'PST',
	'BH',
];
export const KREO_MAIN_ENUM = [
	'',
	'_FN',
	'_S',
	'_LL',
	'_O',
	'_C',
	'_K',
	'_P',
	'_D',
	'_SF',
	'_AP',
	'_RE',
];
export const ACCOUNT_TYPE_ENUM = [
	'',
	'trend: 250 postpaid xmdt',
	'trend: 250 postpaid farm',
	'trend: 250 postpaid xmdt',
	'trend: 250 postpaid farm',
	'trend: 250 prepaid xmdt',
	'trend: 250 prepaid farm',
	'trend: 1000 postpaid xmdt',
	'trend: 1000 postpaid farm',
	'trend: 1000 prepaid xmdt',
	'trend: 1000 prepaid farm',
	'trend: 1500 postpaid xmdt',
	'trend: 1500 postpaid farm',
	'trend: 1500 prepaid xmdt',
	'trend: 1500 prepaid farm',
	'trend: -1 prepaid xmdt',
	'trend: -1 prepaid farm',
	'trend: -1 postpaid xmdt',
	'trend: -1 postpaid farm',
	'bm1(250) xmdt',
	'bm1(250) farm',
	'bm5(250) xmdt',
	'bm5(250) farm',
	'bm5(250)pzrd  xmdt',
	'bm5(250)pzrd  farm',
	'soc9(250)',
	'soc9(-1)',
	'soc9(50)',
	'agg_98',
	'agg_99',
	'agg_ding',
	'agg_luca',
	'agg_china',
	'agg_impact',
	'Profile PZRD + 1500$ limit 2023',
];

export const TIMEFRAMES = [
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday',
	'Saturday',
	'Sunday',
];
